@import "./mixin.scss";
@import "./variable.scss";

.admin-table {
  table {
    width: -webkit-fill-available;
    thead {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      td {
        padding: 10px 15px;
        font-size: 14px;
        white-space: nowrap;
        color: #263a43;
        font-weight: 500;
        white-space: nowrap;

        @include mq("phone-and-tablet") {
          font-size: 12px;
          padding: 8px 12px;
        }
      }
    }
    tbody {
      td {
        padding: 10px 15px;
        font-size: 14px;
        color: #524f4f;
        white-space: nowrap;

        @include mq("phone-and-tablet") {
          font-size: 12px;
          padding: 8px 12px;
        }
      }
    }
  }
}
